<template>
  <div id="app">
    <header class="page-name">
      <h1>WinkzyLive.com</h1>
    </header>
    <Search />
  </div>
</template>

<script>
import Search from './components/MainSearch.vue';

export default {
  components: {
    Search
  }
};
</script>
<style>
#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}
header.page-name {
  background-color: #c850e0;
  color: white;
  padding: 0.5rem;
  border-bottom: 2px solid ##c850e0;
}

header.page-name h1 {
  margin: 0;
  font-size: 1rem;
}
</style>
