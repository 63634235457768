<template>
  <div>
    <header>
      <h2>Tìm Kiếm Dữ Liệu Sao Kê MTTQ</h2>
    </header>
    <!-- <div class="dark-mode-toggle">
      <button @click="toggleDarkMode">{{ darkMode ? 'Chế độ sáng' : 'Chế độ tối' }}</button>
    </div> -->
    <div class="search-container">
      <input
        v-model="query"
        type="text"
        placeholder="Tìm kiếm theo mã giao dịch, số tiền hoặc chi tiết giao dịch"
        @keyup.enter="search"
      />
      <button @click="search">Tìm kiếm</button>
    </div>
    <div class="note">
      Lưu ý: dữ liệu chỉ trong phạm vi từ 1/9/2024 đến 10/9/2024. Chúng tôi sẽ cập nhật khi có dữ liệu mới từ MTTQ.
    </div>
    <div>
      <a href="https://otaku.vn" target="_blank">Tham gia Otaku.vn!</a>
    </div>
    <div v-if="loading">Đang tải...</div>
    <div v-if="!loading && results.length">
      <table>
        <thead>
          <tr>
            <th>Ngày tháng</th>
            <th>Mã giao dịch</th>
            <th>Số tiền</th>
            <th>Nội dung</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in results" :key="item.ma">
            <td class="">{{ item.ngay }}</td>
            <td class="">{{ item.ma }}</td>
            <td class="text-right">{{ formatMoney(item.tien) }}</td>
            <td class="text-left">{{ item.noi_dung }}</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage <= 1">Trước</button>
        <span>Trang {{ currentPage }} / {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage >= totalPages">Sau</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      query: '',
      results: [],
      loading: false,
      currentPage: 1,
      totalPages: 0,
      darkMode: false // Thêm thuộc tính để kiểm soát dark mode
    };
  },
  methods: {
    async search() {
      if (!this.query) return;

      this.loading = true;
      this.currentPage = 1; // Reset trang về 1 khi tìm kiếm từ khóa mới
      try {
        const response = await axios.get('https://api.tracuuyagi.com/ipa/trans', {
          params: {
            s: this.query,
            page: this.currentPage,
          },
        });

        this.results = response.data.data;
        this.totalPages = response.data.last_page;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.loading = false;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.search();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.search();
      }
    },
    formatMoney(value) {
      if (value > 1000) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return value;
    },
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
      document.body.classList.toggle('dark-mode', this.darkMode);
    }
  },
};
</script>

<style scoped>
header {
  background-color: #f5f5f5;
  padding: 0.1em;
  text-align: center;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.3rem 0;
}

input[type="text"] {
  padding: 0.5rem;
  margin-right: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%; /* Đặt độ rộng mặc định để chiếm toàn bộ chiều rộng của container */
  max-width: 500px; /* Đặt độ rộng tối đa */
  box-sizing: border-box; /* Đảm bảo padding và border được tính trong chiều rộng tổng cộng */
}
button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

th, td {
  border: 1px solid #ddd;
  padding: 0.5rem;
}

th {
  background-color: #f5f5f5;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

span {
  margin: 0 1rem;
}

/* Dark mode styles */
.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

.dark-mode header {
  background-color: #1e1e1e;
}

.dark-mode .search-container {
  background-color: #1e1e1e;
}

.dark-mode table {
  border-color: #333;
}

.dark-mode th, .dark-mode td {
  border-color: #333;
}

.dark-mode button {
  background-color: #007bff;
}

.dark-mode button:disabled {
  background-color: #555;
}

.dark-mode .note {
  color: #e0e0e0;
}

.dark-mode .pagination span {
  color: #e0e0e0;
}
</style>
